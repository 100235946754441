import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import Img from 'gatsby-image';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Service = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { description } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { category } = data.markdownRemark.frontmatter;
  const all_pages = data.allMarkdownRemark.edges
  return (
    <Layout bodyClass={`page-${category}`}>
      <SEO 
          title={title}
          description={description} 
       />
      <div className="strip strip-white strip-diagonal">
      <div className="intro pb-2">
                  <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>{title}</h1>
                        </div>
                    </div>
                  </div>
                </div>
        <div className="container pb-2">
        
                { category != "das-unternehmen" ? 
                   data.markdownRemark.frontmatter.featuredImage ? 
                   data.markdownRemark.frontmatter.hero_section ?
                  <div className="pb-6"><Img  fluid={data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid} /></div>
                  :null
                  :null
                  :null
                }
          <div className="row justify-content-start">        
          

            <div className="col-12 col-md-8">
              <div className="service service-single">
                
                <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </div>
            <div className="col-md-4 d-md-none d-lg-inline">
              <nav id="sidebar">
              <div className="sidebar-header">
                  <h3>Übersicht {category}</h3>
              </div>
                <ul className="list-unstyled components">
                  {all_pages.map(edge => (
                    edge.node.frontmatter.category == category && !edge.node.frontmatter.category_content?
                        <li key={"sidebar-links-" + edge.node.frontmatter.title}>
                          <Link 
                            to={edge.node.frontmatter.path}
                            key={"sidebar-links-link-" + edge.node.frontmatter.title}
                          >
                            {edge.node.frontmatter.title}
                          </Link> 
                        </li>
                    : null
                  ))}
                    {category == "werbetechnik" ?
                      <li><Link to="/bildgroessenberechnung">Bildgrößenrechner</Link></li>
                      
                    :null}
                    {category == "werbetechnik" ?
                      <li><Link to="/farbmuster">Farbmuster</Link></li>
                      
                    :null}
                    <li><Link to={category}>Zurück zur Übersicht</Link></li>
                </ul>
              </nav>
            
            </div>
          </div>
        </div>
        <div className="container pt-2">
        <Call button />
      </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        featuredImage{
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        hero_section
        category
      }
      html
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/werbetechnik/|/stanzerei/|/das-unternehmen/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            category
            category_content
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
  }
`;

export default Service;
